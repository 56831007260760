import React, {useState} from 'react';

export const Context = React.createContext();

const Provider = (props) => {
  const [history, setHistory] = useState([]);

  return (
    <Context.Provider value={{
        history,
        addHistory: (newHistory, existingHistory) => {

            if(newHistory !== history[history.length -1]) {
                setHistory([
                    ...history,
                    ...existingHistory,
                    newHistory
                ]);
            }
        },
        getHistoryLink: () => {
            // -2 because -1 is the current one
            return history[history.length-2];
        },
        popHistory: () => {
          // when actually going back, remove the current and new links from history
          return history.splice(-2, 2);
        },
    }} >
        {props.children}
    </Context.Provider>
  );
};

export default ({element}) => {
    return (
      <Provider>
          {element}
      </Provider>
    );
}