// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-floor-js": () => import("./../src/templates/floor.js" /* webpackChunkName: "component---src-templates-floor-js" */),
  "component---src-templates-homepage-js": () => import("./../src/templates/homepage.js" /* webpackChunkName: "component---src-templates-homepage-js" */),
  "component---src-templates-language-js": () => import("./../src/templates/language.js" /* webpackChunkName: "component---src-templates-language-js" */),
  "component---src-templates-map-js": () => import("./../src/templates/map.js" /* webpackChunkName: "component---src-templates-map-js" */),
  "component---src-templates-page-js": () => import("./../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-pages-js": () => import("./../src/templates/pages.js" /* webpackChunkName: "component---src-templates-pages-js" */),
  "component---src-templates-record-js": () => import("./../src/templates/record.js" /* webpackChunkName: "component---src-templates-record-js" */),
  "component---src-templates-room-js": () => import("./../src/templates/room.js" /* webpackChunkName: "component---src-templates-room-js" */),
  "component---src-templates-search-js": () => import("./../src/templates/search.js" /* webpackChunkName: "component---src-templates-search-js" */),
  "component---src-templates-theme-js": () => import("./../src/templates/theme.js" /* webpackChunkName: "component---src-templates-theme-js" */),
  "component---src-templates-themes-js": () => import("./../src/templates/themes.js" /* webpackChunkName: "component---src-templates-themes-js" */)
}

